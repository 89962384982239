<template>
    <div class="top-three">
        <div class="top-three-box">
            <div class="runner-up">
                <div class="tech-content"
                     v-if="!!clockRanking && clockRanking.length > 1">
                    <div class="avatar-box">
                        <img class="avatar-img"
                             :src="clockRanking[1].avatar"
                             :onerror="defaultAvatar">
                    </div>
                    <p class="staff-no">{{clockRanking[1].staff_no}}</p>
                    <div class="clock-box">
                        <div class="clock-context">
                            <p class="clock-name">总钟</p>
                            <p class="clock-num">{{clockRanking[1].total_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">点钟</p>
                            <p class="clock-num">{{clockRanking[1].dot_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">加钟</p>
                            <p class="clock-num">{{clockRanking[1].add_clock|valueKbits}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="champion">
                <div class="tech-content"
                     v-if="!!clockRanking && clockRanking.length > 0">
                    <div class="avatar-box">
                        <img class="avatar-img"
                             :src="clockRanking[0].avatar"
                             :onerror="defaultAvatar">
                    </div>
                    <p class="staff-no">{{clockRanking[0].staff_no}}</p>
                    <div class="clock-box">
                        <div class="clock-context">
                            <p class="clock-name">总钟</p>
                            <p class="clock-num">{{clockRanking[0].total_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">点钟</p>
                            <p class="clock-num">{{clockRanking[0].dot_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">加钟</p>
                            <p class="clock-num">{{clockRanking[0].add_clock|valueKbits}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="third-place">
                <div class="tech-content"
                     v-if="!!clockRanking && clockRanking.length > 2">
                    <div class="avatar-box">
                        <img class="avatar-img"
                             :src="clockRanking[2].avatar"
                             :onerror="defaultAvatar">
                    </div>
                    <p class="staff-no">{{clockRanking[2].staff_no}}</p>
                    <div class="clock-box">
                        <div class="clock-context">
                            <p class="clock-name">总钟</p>
                            <p class="clock-num">{{clockRanking[2].total_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">点钟</p>
                            <p class="clock-num">{{clockRanking[2].dot_clock|valueKbits}}</p>
                        </div>
                        <div class="clock-context">
                            <p class="clock-name">加钟</p>
                            <p class="clock-num">{{clockRanking[2].add_clock|valueKbits}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:"top-three",
		props:{
			clockRanking:Array
		},
		computed:{
			defaultAvatar(){
				return 'this.src="' + require('@/assets/images/avatar-default.png') +
						'";this.className="default-avatar"'
			},
		},
		data(){
			return {}
        },
        filters:{
            valueKbits:(value) => {
                let result="";
                if(!value || value == '.00'||value == '0.00'){
                result = "0"
                }else{
                value = parseFloat(value)
                result=(value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                }
                return result
            },
        },
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    %avatar-box-before{
        content:'';
        display:block;
        width:42px;
        height:26px;
        background:url("~@/assets/images/runner-up-icon.png") no-repeat center;
        background-size:100%;
        position:absolute;
        top:-22px;
        left:50%;
        transform:translateX(-50%);
    }

    %avatar-box-after{
        content:'2';
        display:block;
        width:24px;
        height:24px;
        font-size:18px;
        border-radius:50%;
        text-align:center;
        line-height:24px;
        background:#788896;
        position:absolute;
        bottom:-9px;
        left:50%;
        transform:translateX(-50%);
    }

    .top-three{
        height:374px;
    }

    .top-three-box{
        @extend %flex-center-row;
        padding:0 28px;
        color:#FFFFFF;
        height:100%;
    }

    .runner-up,
    .third-place,
    .champion{
        height:100%;
    }

    .tech-content{
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        align-items:center;
    }

    .clock-box{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-around;
    }

    .runner-up{
        width:200px;

        .clock-box{
            height:115px;
            margin:18px 0 0;
        }

        .avatar-box:before{
            @extend %avatar-box-before;
        }

        .avatar-box:after{
            @extend %avatar-box-after;
        }
    }

    .champion{
        flex:1;

        .clock-box{
            height:186px;
            margin:10px 0 0;
        }

        .avatar-box:before{
            @extend %avatar-box-before;
            width:52px;
            height:32px;
            background:url("~@/assets/images/champion-icon.png") no-repeat center;
            background-size:100%;
            top:-26px;
        }

        .avatar-box:after{
            @extend %avatar-box-after;
            content:'1';
            background:#FF9500;
        }
    }

    .third-place{
        width:200px;
        .clock-box{
            height:98px;
            margin:12px 0 0;
        }

        .avatar-box:before{
            @extend %avatar-box-before;
            background:url("~@/assets/images/third-place-icon.png") no-repeat center;
            background-size:100%;
        }

        .avatar-box:after{
            @extend %avatar-box-after;
            content:'3';
            background:#DD7459;
        }
    }

    .clock-context{
        text-align:center;
    }

    .clock-name{
        font-size:20px;
        margin:0 0 10px;
    }

    .clock-num{
        font-size:28px;
        font-weight:bold;
    }

    .staff-no{
        font-size:30px;
        font-weight:bold;
        padding:20px 0 0;
    }

    .avatar-box{
        position:relative;
        height:90px;
        width:90px;
        border-radius:50%;
        background:#007AFF;

        .avatar-img{
            width:90px;
            height:90px;
            border-radius:50%;
            object-fit:cover;
        }

        .default-avatar{
            width:40px;
            height:40px;
            margin:25px;
        }
    }
</style>